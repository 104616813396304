<template>
  <b-row class="match-height faq-margin">
    <b-col v-if="showFaqLink" lg="3" md="12">
      <template>
        <b-card class="faq-area">
          <div class="faq-section">
            <feather-icon icon="MessageSquareIcon" class="faq-icon" />
            <span>
              {{ $t("Pages.Ticket.Title.before_send_ticket") }}
            </span>
            <p>
              {{ $t("Pages.Ticket.Title.please_page")
              }}<span>
                {{ $t("Pages.Ticket.Title.frq_q") }}
              </span>
              {{ $t("Pages.Ticket.Title.check_out") }}
            </p>
            <b-button :to="{ name: 'support' }" variant="primary">
              {{ $t("Pages.Ticket.Title.frq_q") }}
            </b-button>
          </div>
        </b-card>
      </template>
    </b-col>
    <b-col lg="9" md="12">
      <template>
        <b-card>
          <b-form @submit.prevent>
            <b-row>
              <b-col cols="12">
                <b-form-group 
                :label="$t('Pages.Ticket.Label.ticket_title')"
                 label-for="h-title">
                  <b-form-input
                    id="email-subject"
                    v-model="composeData.title"
                    :placeholder="$t('Pages.Ticket.Placeholder.ticket_title')"
                  />
                </b-form-group>
              </b-col>
              <!-- <b-col cols="4">
                <b-form-group
                  :label="$t('Pages.Ticket.Label.Department')"
                  label-for="h-title"
                >
                  <v-select
                  v-model="composeData.department"
                  dir="rtl"
                  label="name"
                  class="flex-grow-1 email-to-selector"
                  :options="department"
                  input-id="email-to"        
                >
                </v-select>
                </b-form-group>
              </b-col> -->
              <b-col cols="12">
                <b-form-group :label="$t('Pages.Ticket.Label.Description')" label-for="h-title">
                  <b-form-textarea
                    id="quil-content"
                    :placeholder="$t('Pages.Ticket.Placeholder.write_description')"
                    v-model="composeData.content"
                    rows="9"
                    style="resize: none"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="d-flex justify-content-end align-items-center">
              <label for="myInputFile" class="mx-2">
                <feather-icon
                  icon="PaperclipIcon"
                  size="17"
                  class="ml-2 cursor-pointer"
                />
                <span v-if="composeData.file" class="px-1">{{
                  $t("Pages.Ticket.Title.selected_file")
                }}</span>
                <input
                  type="file"
                  @change="selectFile"
                  id="myInputFile"
                  style="display: none"
                />
              </label>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class=""
                @click="send"
              >
                {{ $t("Pages.Ticket.Title.submit_ticket") }}
              </b-button>
            </div>
          </b-form>
        </b-card>
      </template>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
  BButton,
  BCard,

} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { userInfoDao } from "@/daos/userInfo/userInfoDao";
const yotubeAccounts = process.env.VUE_APP_YOUTUBE_ACCOUNTS;

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
    BButton,
    BCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      composeData: {
        file: null,
        title: "",
        content: "",
      },
      editorOption: {
        modules: {
          toolbar: "#quill-toolbar",
        },
        placeholder: this.$t("Base.Placeholder.msg_txt"),
      },
      department: [],
      sending: false,
      upload_response: null,
      loading: false,
      userEmail:null
    };
  },
  created() {
    this.getListDepartment();
    this.handleGetUserInfo();
  },
  computed:{
    showFaqLink(){
      const youtubeAccountsArray = yotubeAccounts.split(",");
      if (youtubeAccountsArray.includes(this.userEmail)) {
        return false;
      }
      return true
    }
  },
  methods: {
    send() {
      if (!this.sending) {
        if (this.composeData.file) {
          this.sendEmailWithFile();
        } else {
          this.sendEmail();
        }
      }
    },
    sendEmailWithFile() {
      if (!this.loading) {
        this.$http.get("/api/support/token-upload").then((response) => {
          this.token_upload = response.data.token;
          if (this.composeData.title != "" && this.composeData.content != "") {
            this.loading = true;
            let data = new FormData();
            data.append("api_key", this.token_upload);
            data.append("file", this.composeData.file);
            axios
              .post("https://storage.master.tube/process.php", data)
              .then((response) => {
                this.upload_response = response.data.data.data;
                let appendData = new FormData();
                appendData.append("title", this.composeData.title);
                appendData.append("content", this.composeData.content);
                appendData.append(
                  "department",
                  this.composeData.department
                    ? this.composeData.department.value
                    : 1
                );
                appendData.append(
                  "attachment",
                  JSON.stringify(this.upload_response)
                );
                this.$http
                  .post("/api/support/add", appendData)
                  .then((response) => {
                    this.sending = false;
                    this.$swal({
                      title: this.$t("Base.Alert.Successful"),
                      text: this.$t("Base.Alert.success_ticket_submit"),
                      icon: "success",
                      confirmButtonText: this.$t("Base.Alert.Understand"),
                    }).then((ok) => {
                      this.$router.push({ name: "ticket" });
                    });
                  });
              });
          } else {
            this.makeToast(
              this.$t("Base.Alert.Error"),
              this.$t("Base.Alert.fill_ticket_title"),
              "danger"
            );
          }
        });
      }
    },
    sendEmail() {
      if (!this.loading) {
        if (this.composeData.title != "" && this.composeData.content != "") {
          this.loading = true;
          let data = new FormData();
          data.append("title", this.composeData.title);
          data.append("content", this.composeData.content);
          data.append(
            "department",
            this.composeData.department ? this.composeData.department.value : 1
          );
          this.$http.post("/api/support/add", data).then((response) => {
            this.sending = false;
            this.$swal({
              title: this.$t("Base.Alert.Successful"),
              text: this.$t("Base.Alert.success_ans_submit"),
              icon: "success",
              confirmButtonText: this.$t("Base.Alert.Understand"),
            }).then((ok) => {
              this.$router.push({ name: "ticket" });
            });
          });
        } else {
          this.makeToast(
            this.$t("Base.Alert.Error"),
            this.$t("Base.Alert.fill_ticket_title"),
            "danger"
          );
        }
      }
    },
    getListDepartment() {
      this.$http.get("/api/support/department").then((response) => {
        this.department = response.data;
        this.composeData.department = this.department[0];
      });
    },
    selectFile(e) {
      if (e.target.files.length) {
        this.composeData.file = e.target.files[0];
      } else {
        this.composeData.file = null;
      }
    },
    async handleGetUserInfo() {
      const userInfo = await userInfoDao.getUserInfo();
      this.userEmail = userInfo.email;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.faq-area .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
}
.faq-area .card-body {
  font-size: 16px;
  line-height: normal;
}
.faq-area .card-body p {
  margin-bottom: 30px;
  margin-top: 5px;
}
.faq-area .card-body p span {
  color: var(--primary);
  font-weight: bold;
}
.faq-section .faq-icon {
  height: 50px; /* Adjust the font size to your liking */
  width: 50px;
  display: block;
  margin: 0 auto 30px auto;
  color: var(--primary);
}
.faq-margin {
  margin-top: 0.85rem;
}
</style>
